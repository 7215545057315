<template>
  <b-card-code
    title=""
    no-body
  >
    <b-card-body>
      <!-- upload csv button -->
      <div class="d-flex justify-content-end  flex-wrap">
        <b-button
          style="height: 38px;"
          size="md"
          variant="warning"
          @click="exportCSV"
        >
          Export CSV
        </b-button>
        <label
          for="import-csv"
          class="btn btn-success"
        >
          Import CSV
        </label>
        <b-form-file
          id="import-csv"
          v-model="file"
          class="d-none"
          @input="uploadCSV"
        />
      </div>
    </b-card-body>

    <!-- facility code table -->
    <facility-code-table
      v-if="items.length > 0"
      :items="items"
      @editAction="editAction"
      @deleteAction="deleteAction"
    />
        
    <!-- edit modal facility code -->
    <edit-facility-code-modal
      :code="code"
      @getFacilityCodes="getFacilityCodes"
    />

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCardBody, BFormFile, VBModal, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as facilityService from '@/services/facility'
import EditFacilityCodeModal from './EditFacilityCodeModal.vue'
import FacilityCodeTable from './FacilityCodeTable.vue'

export default {
  components: {
    BCardCode,
    BCardBody,
    BFormFile,
    BButton,
    EditFacilityCodeModal,
    FacilityCodeTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      file: null,
      items: [],
      code: {
        name: '',
        city: '',
        address: '',
        state: '',
        abbreviated_state: '',
        zipcode: '',
      },
    }
  },

  computed: {
  },

  mounted() {
    this.getFacilityCodes()
  },

  methods: {
    /** get facility code */
    getFacilityCodes() {
      this.$store.commit('set_loading_action', true)
      facilityService.getCodes()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.items = res.data.facilityCodes
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('set_loading_action', false)
        })
    },

    /** get facility code by ID */
    getData(id) {
      facilityService.getCodeByID(id)
        .then(res => {
          this.code = res.data.code
        })
        .catch(err => {
          console.log(err)
        })
    },

    /** Upload CSV */
    uploadCSV(file) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const fileReader = new FileReader()
          /** read file as string */
          fileReader.readAsBinaryString(file)
          fileReader.onload = e => {
            const data = e.target.result
            // eslint-disable-next-line no-undef
            const workbook = XLSX.read(data, { type: 'binary' })
            const rowObjects = []
            workbook.SheetNames.forEach(sheet => {
              // eslint-disable-next-line no-undef
              const rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
              rowObjects.push(rowObject)
            })
            /** call rearrange function */
            this.importCSV(rowObjects)
          }
        }
      })
    },

    /** Post Upload CSV */
    importCSV(json) {
      this.$store.commit('set_loading_action', true)
      facilityService.importCodeInfo({ payload: json[0] })
        .then(() => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Success!',
            text: 'CSV has been uploaded successfully!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              this.getFacilityCodes()
            }
          })
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          this.$swal({
            title: 'Failed!',
            text: err.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    // export facility codes to csv file
    exportCSV() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, upload it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          facilityService.exportCodeCsv()
            .then(res => {
              const url = window.URL.createObjectURL(
                new Blob([res.data], { type: 'text/csv' }),
              )

              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'facility_codes.csv')
              document.body.appendChild(link)

              link.click()

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Facility Code has been exported successfully!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
            })
        }
      })
    },

    deleteAction(id) {
      console.log(id)
    },

    editAction(id) {
      this.getData(id)
      this.$bvModal.show('facility-code')
    },
  },
}
</script>
