import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getCodes = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.facility.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getCodeByID = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.facility.edit}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const updateCode = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.facility.update}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const importCodeInfo = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.facility.importCodeInfo}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const sequences = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.facility.sequence}`, { payload: params.payload }, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const exportCodeCsv = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.facility.exportCodeCsv}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
